import { useCallback, useContext, useEffect, useState } from 'react';
import { reactTableContext } from '../Table';
import Collapsible from './Collapsible';
import ColumnShowHideMove from './ColumnShowHideMove';

function TableSettings() {
    const { tableTitle } = useContext(reactTableContext);

    const [open, setOpen] = useState(false);
    /**
     *
     */
    const openOverlyHandler = () => {
        setOpen(true);
    };
    const closeOverlyHandler = () => {
        setOpen(false);
    };
    const closeOnOutsideClick = useCallback((e) => {
        if (e.target.id === '_s_overly_wrapper') closeOverlyHandler();
    }, []);
    // const checkBoxOnChangeHandler = (e) => {
    //     e.target.checked && setHiddenColumns(e.target.value);
    // };

    useEffect(() => {
        document.addEventListener('click', closeOnOutsideClick);
        return () => {
            document.removeEventListener('click', closeOnOutsideClick);
        };
    }, [closeOnOutsideClick]);
    return (
        <>
            <div onClick={openOverlyHandler} className='_s_setting_modal_toggle_wrapper'>
                <svg
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth='0'
                    viewBox='0 0 512 512'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='32'
                        d='M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z'
                    ></path>
                </svg>
            </div>
            <div
                className={`_s_overly_wrapper ${open ? '_s_open_overly' : ''}`}
                id='_s_overly_wrapper'
            >
                <div className='_s_overly_content_wrapper'>
                    {/* Overly Header ----Start---- */}
                    <div className='_s_overly_header'>
                        {<h3>{tableTitle || 'Settings'}</h3>}
                        <div className='_s_close_overly_btn' onClick={closeOverlyHandler}>
                            <svg
                                stroke='currentColor'
                                fill='currentColor'
                                strokeWidth='0'
                                viewBox='0 0 512 512'
                                height='1.2em'
                                width='1.2em'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path d='M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z'></path>
                            </svg>
                        </div>
                    </div>
                    {/* Overly Header ----End---- */}

                    {/* Overly Body ----End---- */}
                    <div className='_s_overly_body'>
                        <Collapsible header='Hide & order column'>
                            <ColumnShowHideMove />
                        </Collapsible>
                    </div>
                    {/* Overly Body ----End---- */}
                </div>
            </div>
        </>
    );
}

export default TableSettings;
